import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { Ionicons } from '@expo/vector-icons';
import { Paragraph, Surface } from 'react-native-paper';

class StepIcon extends Component {
  render() {
    let styles;

    let stepIconSize = this.props.stepIconSize < this.props.stepWidth - 16 ? this.props.stepIconSize : this.props.stepWidth - 16;

    if (this.props.isActiveStep) {
      styles = {
        circleStyle: {
          width: stepIconSize,
          height: stepIconSize,
          borderRadius: stepIconSize / 2,
          backgroundColor: this.props.activeStepIconColor,
          borderColor: this.props.activeStepIconBorderColor,
          borderWidth: 5,
          bottom: 1,
        },
        labelText: {
          textAlign: 'center',
          flexWrap: 'wrap',
          width: this.props.stepWidth,
          paddingTop: 4,
          fontFamily: this.props.labelFontFamily,
          color: this.props.activeLabelColor,
          fontSize: this.props.activeLabelFontSize || this.props.labelFontSize,

        },
        leftBar: {
          position: 'absolute',
          top: (stepIconSize - this.props.borderWidth) / 2,
          left: 0,
          right: stepIconSize / 2,
          borderTopStyle: this.props.borderStyle,
          borderTopWidth: this.props.borderWidth,
          borderTopColor: this.props.completedProgressBarColor,
          marginRight: this.props.stepWidth / 2,
        },
        rightBar: {
          position: 'absolute',
          top: (stepIconSize - this.props.borderWidth) / 2,
          right: 0,
          left: stepIconSize / 2,
          borderTopStyle: this.props.borderStyle,
          borderTopWidth: this.props.borderWidth,
          borderTopColor: this.props.progressBarColor,
          marginLeft: this.props.stepWidth / 2,
        },
        stepNum: {
          marginTop: this.props.borderWidth,
          fontSize: 20
        },
      };
    } else if (this.props.isCompletedStep) {
      styles = {
        circleStyle: {
          top: this.props.borderWidth,
          width: (stepIconSize * .9),
          height: (stepIconSize * .9),
          borderRadius: (stepIconSize * .9) / 2,
          backgroundColor: this.props.completedStepIconColor,
        },
        labelText: {
          textAlign: 'center',
          flexWrap: 'wrap',
          width: this.props.stepWidth,
          paddingTop: 4,
          fontFamily: this.props.labelFontFamily,
          color: this.props.completedLabelColor,
          marginTop: 4,
          fontSize: this.props.labelFontSize,
        },
        leftBar: {
          position: 'absolute',
          top: (stepIconSize - this.props.borderWidth) / 2,
          left: 0,
          right: Math.ceil(this.props.stepWidth / 2),
          borderTopStyle: this.props.borderStyle,
          borderTopWidth: this.props.borderWidth,
          borderTopColor: this.props.completedProgressBarColor,
          marginRight: Math.floor((stepIconSize * .9) / 2) - 1,
        },
        rightBar: {
          position: 'absolute',
          top: (stepIconSize - this.props.borderWidth) / 2,
          right: 0,
          left: Math.ceil(this.props.stepWidth / 2),
          borderTopStyle: this.props.borderStyle,
          borderTopWidth: this.props.borderWidth,
          borderTopColor: this.props.completedProgressBarColor,
          marginLeft: Math.floor((stepIconSize * .9) / 2) - 1,
        },
        stepNum: {
          fontSize: 20
        },
      };
    } else {
      styles = {
        circleStyle: {
          top: this.props.borderWidth,
          width: (stepIconSize * .9),
          height: (stepIconSize * .9),
          borderRadius: (stepIconSize * .9) / 2,
          backgroundColor: this.props.disabledStepIconColor,
        },
        labelText: {
          textAlign: 'center',
          flexWrap: 'wrap',
          width: this.props.stepWidth,
          paddingTop: 4,
          fontFamily: this.props.labelFontFamily,
          color: this.props.labelColor,
          marginTop: 4,
          fontSize: this.props.labelFontSize,
        },
        leftBar: {
          position: 'absolute',
          top: (stepIconSize - this.props.borderWidth) / 2,
          left: 0,
          right: Math.ceil(this.props.stepWidth / 2),
          borderTopStyle: this.props.borderStyle,
          borderTopWidth: this.props.borderWidth,
          borderTopColor: this.props.progressBarColor,
          marginRight: Math.floor((stepIconSize * .9) / 2) - 1,
        },
        rightBar: {
          position: 'absolute',
          top: (stepIconSize - this.props.borderWidth) / 2,
          right: 0,
          left: Math.ceil(this.props.stepWidth / 2),
          borderTopStyle: this.props.borderStyle,
          borderTopWidth: this.props.borderWidth,
          borderTopColor: this.props.progressBarColor,
          marginLeft: Math.floor((stepIconSize * .9) / 2) - 1,
        },
        stepNum: {
          fontSize: 20
        },
      };
    }

    return (
      <View style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Surface style={{ ...styles.circleStyle, justifyContent: 'center', alignContent: 'center', alignSelf: 'center'}}>
          {this.props.isCompletedStep ? (
            <Ionicons color={this.props.completedCheckColor} name="checkmark-outline" size={24} style={{ textAlign: 'center' }} />
          ) : (
            <Paragraph style={{ ...styles.stepNum, textAlign: 'center',color:"white" }}>{this.props.stepNum}</Paragraph>
          )}
        </Surface>
        <Paragraph style={styles.labelText}>{this.props.label}</Paragraph>
        {!this.props.isFirstStep && <View style={styles.leftBar} />}
        {!this.props.isLastStep && <View style={styles.rightBar} />}
      </View>
    );
  }
}

StepIcon.propTypes = {
  stepCount: PropTypes.number.isRequired,
  stepNum: PropTypes.number.isRequired,
  isFirstStep: PropTypes.bool.isRequired,
  isLastStep: PropTypes.bool.isRequired,

  borderWidth: PropTypes.number,
  borderStyle: PropTypes.string,
  activeStepIconBorderColor: PropTypes.string,

  progressBarColor: PropTypes.string,
  completedProgressBarColor: PropTypes.string,

  activeStepIconColor: PropTypes.string,
  disabledStepIconColor: PropTypes.string,
  completedStepIconColor: PropTypes.string,

  labelFontFamily: PropTypes.string,
  labelColor: PropTypes.string,
  labelFontSize: PropTypes.number,
  activeLabelColor: PropTypes.string,
  activeLabelFontSize: PropTypes.number,
  completedLabelColor: PropTypes.string,

  activeStepNumColor: PropTypes.string,
  completedStepNumColor: PropTypes.string,
  disabledStepNumColor: PropTypes.string,

  completedCheckColor: PropTypes.string,

  stepIconSize: PropTypes.number,
  stepWidth: PropTypes.number,
};

StepIcon.defaultProps = {
  stepIconSize: 60,
  stepWidth: 100,

  borderWidth: 3,
  borderStyle: 'solid',
  activeStepIconBorderColor: '#4BB543',

  progressBarColor: '#ebebe4',
  completedProgressBarColor: '#4BB543',

  activeStepIconColor: 'transparent',
  completedStepIconColor: '#4BB543',
  disabledStepIconColor: '#ebebe4',

  labelColor: 'lightgray',
  labelFontSize: 14,
  activeLabelColor: '#4BB543',
  completedLabelColor: 'lightgray',

  activeStepNumColor: 'black',
  completedStepNumColor: 'black',
  disabledStepNumColor: 'white',

  completedCheckColor: 'white',
};

export default StepIcon;
