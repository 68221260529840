import React, { useEffect, useState } from "react";
import {StyleSheet, View} from "react-native";
import { Headline, Paragraph, Subheading } from "react-native-paper";
import ThemedSlider from "../components/themed-sliders/ThemedSlider";
import { assessmentDescriptions } from "../data/constants";
import { store } from "../redux/store";
import LanguageKeywords from "../interfaces/LanguageKeywords";

type SensorStep1Props = {
    onValueChange: (value: number) => void;
}

interface DICTIONARY_KEY {
    [key: number]: string;
}

const SensorStep1 = ({ onValueChange }: SensorStep1Props): React.ReactElement => {
    let _value = store.getState().assessments.currentAssessment.assessmentMain || 6;
    const [value, setValue] = useState<number>(_value);

    let dictionary = store.getState().assessments.translationConfiguration.dictionary;
    let desc = assessmentDescriptions;

    const dictionaryKeys = {
        1: dictionary["user_main1"],
        2: dictionary["user_main2"],
        3: dictionary["user_main3"],
        4: dictionary["user_main4"],
        5: dictionary["user_main5"],
        6: dictionary["user_main6"],
        7: dictionary["user_main6"],
        8: dictionary["user_main8"],
        9: dictionary["user_main9"],
        10: dictionary["user_main10"],
    } as DICTIONARY_KEY

    useEffect(() => {
        onValueChange(value);
    }, [value])

    const styles = StyleSheet.create({
        outerView: {
            alignItems: "center"
        },
        subheading: {
            textAlign: "center",
            marginBottom: 32
        },
        viewThemeSlider:{
            width: "100%"
        },
        paragraph:{
            alignSelf: "center",
            marginBottom: 16,
            fontWeight: "bold"
        },
        paragraphBottom:{
            marginBottom: 50
        }
    });

    return (
        <View style={styles.outerView}>
            <Headline>{dictionary["main_title"]}</Headline>
            <Subheading style={styles.subheading}>{dictionary["main_header"]}</Subheading>
            <Paragraph style={styles.paragraph}>
                {dictionary["value"]}: {value}
            </Paragraph>
            <Paragraph style={styles.paragraphBottom}>
                {dictionaryKeys[value]}
            </Paragraph>
            <View style={styles.viewThemeSlider}>
                <ThemedSlider
                    value={value}
                    onValueChange={(v) => setValue(v)}
                />
            </View>


        </View >
    );
}
export default SensorStep1;