import axios, { AxiosInstance, AxiosResponse } from "axios";
import Config from '../pweltconfig.json';


interface PweltConfig {
    REACT_APP_API_URL: string;
}

// TODO Config
//const baseUrl = "http://192.168.1.214:8080/api/v1/";
export enum Endpoint {
    CreateUnitAssessment =  "assessment/createUnitAssessment/",
    GetUnitAssessmentEventAvailable = "assessmentEvent/unit/available",
}

export enum RequestMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE"
}

const axiosInstance = axios.create({
    //baseURL: baseUrl,
    baseURL: `${(Config as PweltConfig).REACT_APP_API_URL}/api/v1/`,
}) as AxiosInstance;

export const makeApiRequest = async (endpoint: Endpoint, method: RequestMethod,token: string , data?: any, pathVariablesString?: string): Promise<any> => {

    switch (method) {
        case RequestMethod.GET:
            return new Promise((resolve, reject) => {
                axiosInstance.get(endpoint + (data ? data : ""), {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${token}`
                    }
                })
                    .then((response: AxiosResponse) => {
                        resolve(response);
                    })
                    .catch((reason) => {
                        reject(reason);
                    });
            });
        case RequestMethod.POST:
            return new Promise((resolve, reject) => {
                axiosInstance.post(endpoint+pathVariablesString, (data ? data : ""), {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${token}`
                    }
                })
                    .then((response: AxiosResponse) => {
                        resolve(response);
                    })
                    .catch((reason) => {
                        reject(reason);
                    });
            });
        case RequestMethod.PUT:
            return new Promise((resolve, reject) => {
                axiosInstance.put(endpoint + (data ? data : ""),{
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${token}`
                    }
                })
                    .then((response: AxiosResponse) => {
                        resolve(response);
                    })
                    .catch((reason) => {
                        reject(reason);
                    });
            });
        case RequestMethod.PATCH:
            return new Promise((resolve, reject) => {
                axiosInstance.patch(endpoint + (data ? data : ""),{
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${token}`
                    }
                })
                    .then((response: AxiosResponse) => {
                        resolve(response);
                    })
                    .catch((reason) => {
                        reject(reason);
                    });
            });
        case RequestMethod.DELETE:
            return new Promise((resolve, reject) => {
                axiosInstance.delete(endpoint + (data ? data : ""),{
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": `Bearer ${token}`
                    }
                })
                    .then((response: AxiosResponse) => {
                        resolve(response);
                    })
                    .catch((reason) => {
                        reject(reason);
                    });
            });
        default:
            console.error("Unsupported request method.");
            break;
    }
}