import Keycloak from "keycloak-js";
import keycloakSettings from "../keycloak-settings.json"
import demoModeData from "../demo-mode.json";

const keycloak = new Keycloak(keycloakSettings);

try {
    if(!demoModeData.isOn) {
        const authenticated = await keycloak.init({
            onLoad: 'login-required',
        });
        console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);
    }
} catch (error) {
    console.error('Failed to initialize adapter:', error);
}

export default keycloak;