export const assessmentDescriptions: string[] = [
    "",
    "user_main1",
    "user_main2",
    "user_main3",
    "user_main4",
    "user_main5",
    "user_main6",
    "user_main7",
    "user_main8",
    "user_main9",
    "user_main10"
]

export const prognosisDescriptions: string[] = [
    "much_better",
    "better",
    "same",
    "worse",
    "much_worse"
];

export const impactFactorDescriptions: string[] = [
    "no_impact",
    "some_impact",
    "great_impact"
];
