import React, { useEffect, useState } from "react";
import {StyleSheet, View} from "react-native";
import { Headline, Paragraph, Subheading, Text } from "react-native-paper";
import ThemedRangeSlider from "../components/themed-sliders/ThemedRangeSlider";
import { store } from "../redux/store";

type SensorStep2Props = {
    onValueChange: (value: [number, number]) => void;
}

interface DICTIONARY_KEY {
    [key: number]: string;
}

const SensorStep2 = ({ onValueChange }: SensorStep2Props): React.ReactElement => {
    let _value: [number, number] = [store.getState().assessments.currentAssessment.assessmentMin, store.getState().assessments.currentAssessment.assessmentMax];
    _value = _value && _value[0] && _value[1] ? _value : [3, 8];
    const [value, setValue] = useState<[number, number]>(_value);

    let dictionary = store.getState().assessments.translationConfiguration.dictionary;

    const dictionaryKeyValueMax = {
        1: dictionary["min1"],
        2: dictionary["min2"],
        3: dictionary["min3"],
        4: dictionary["min4"],
        5: dictionary["min5"],
        6: dictionary["min6"],
        7: dictionary["min7"],
        8: dictionary["min8"],
        9: dictionary["min9"],
        10: dictionary["min10"],
    } as DICTIONARY_KEY

    useEffect(() => {
        onValueChange(value);
    }, [value])

    const styles = StyleSheet.create({
        outerView: {
            alignItems: "center"
        },
        subheading: {
            textAlign: "center",
            marginBottom: 32
        },
        viewThemeSlider:{
            width: "100%"
        },
        paragraphMinimum:{
            textAlign: "center",
            marginBottom: 16,
            marginTop:8,
        },
        textMinimum:{
            textAlign: 'center',
            fontWeight: "bold"
        },
        paragraphMaximum:{
            textAlign: 'center',
            marginBottom: 40
        },
        textMaximum:{
            fontWeight: 'bold'
        }
    });

    return (
        <View style={styles.outerView}>
            <Headline>{dictionary["min_max_title"]}</Headline>
            <Subheading style={styles.subheading}>{dictionary["min_max_header"]}</Subheading>

            <Paragraph style={styles.paragraphMinimum}>
                <Text style={styles.textMinimum}>{dictionary["min"]}: {value[0]}{"\n"}</Text>
                {dictionaryKeyValueMax[value[0]]}
            </Paragraph>
            <Paragraph style={styles.paragraphMaximum}>
                <Text style={styles.textMaximum}>{dictionary["max"]}: {value[1]}{"\n"}</Text>
                {dictionaryKeyValueMax[value[1]]}
            </Paragraph>

            <View style={styles.viewThemeSlider}>
                <ThemedRangeSlider
                    value={value}
                    onValueChange={(v) => setValue(v)}
                />
            </View>


        </View >
    );
}
export default SensorStep2;