import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import {
  Headline,
  RadioButton,
  Subheading,
  Text,
  TextInput,
  Title,
} from "react-native-paper";
import { impactFactorDescriptions } from "../data/constants";
import KeyValuePair from "../interfaces/KeyValuePair";
import { store } from "../redux/store";

type SensorStep4Props = {
  onValueChange: (value: KeyValuePair[]) => void;
};

interface DICTIONARY_KEY {
    [key: number]: string;
}

const styles = StyleSheet.create({
    outerViewRadioButtons:{
        flexDirection: "row",
        alignContent: "center"
    },
    radioButtonsTextDescription:{
        alignSelf: "center",
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
    },
    outerView:{
        alignItems: "center",
        marginHorizontal: 16
    },
    subheading:{
        textAlign: "center",
        marginBottom: 32
    },
    innerViewRadioButtons:{
        alignItems: "stretch",
        alignSelf: "flex-start",
        width: "100%",
    },
    impactFactorViewRadioButtons:{
        marginBottom: 32
    },
    textInput:{
        width: "100%"
    },
    textInputContent:{
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    }
});

const renderValues = () => {
  let items: React.ReactElement[] = [];
  let values = ["0", "1", "2"];
  let dictionary = store.getState().assessments.translationConfiguration.dictionary;

    const dictionaryKeyValueImpact = {
        0: dictionary["no_impact"],
        1: dictionary["some_impact"],
        2: dictionary["great_impact"],
    } as DICTIONARY_KEY

  for (let i = 0; i < values.length; i++) {
    items.push(
      <View key={i} style={styles.outerViewRadioButtons}>
        <RadioButton value={values[i]} />
        <Text style={styles.radioButtonsTextDescription}>
          {dictionaryKeyValueImpact[i]}
        </Text>
      </View>,
    );
  }
  return items;
};

const SensorStep4 = ({ onValueChange }: SensorStep4Props) => {
  let _impactFactors = [] as KeyValuePair[];
  store
    .getState()
    .assessments.currentAssessmentEventConfiguration.configuration.impactFactorConfigurations.map(
      //(ifc: any) => _impactFactors.push(ifc.impactFactor),
      (ifc: any) => _impactFactors.push({displayName: ifc.displayName, commentVisible: ifc.commentVisible, impactFactorId: ifc.impactFactorId, comment: "", value: 0} as KeyValuePair),
    );
    let dictionary = store.getState().assessments.translationConfiguration.dictionary;

  const [impactFactors, setImpactFactors] =
    useState<KeyValuePair[]>(_impactFactors);

  useEffect(() => {
    onValueChange(impactFactors);
  }, [impactFactors]);

  return (
    <View style={styles.outerView}>
      <Headline>{dictionary["impact_factor_title"]}</Headline>
      <Subheading style={styles.subheading}>
          {dictionary["impact_factor_header"]}
      </Subheading>

      <View
        style={styles.innerViewRadioButtons}
      >
        {impactFactors.map((_impactFactor, index) => {
          const [impactFactor, setImpactFactor] =
            useState<KeyValuePair>(_impactFactor);

          useEffect(() => {
            let temp = [...impactFactors];
            temp[
              temp.findIndex(
                (a) => a.impactFactorId === impactFactor.impactFactorId,
              )
            ] = impactFactor;
            console.log(impactFactors);
            console.log(temp);
            setImpactFactors(temp);
          }, [impactFactor]);

          return (
            <View key={"impactFactor" + index} style={styles.impactFactorViewRadioButtons}>
              <Title>{impactFactor.displayName}</Title>
              <RadioButton.Group
                value={impactFactor.value + ""}
                onValueChange={(value: string) =>
                  setImpactFactor({ ...impactFactor, value: +value })
                }
              >
                {renderValues()}
              </RadioButton.Group>
              {!impactFactor.commentVisible ? null : (
                <TextInput
                  value={impactFactor.comment}
                  label="Kommentar"
                  multiline
                  mode="outlined"
                  style={styles.textInput}
                  contentStyle={styles.textInputContent}
                  onChangeText={(t) =>
                    setImpactFactor({ ...impactFactor, comment: t })
                  }
                />
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
};
export default SensorStep4;

