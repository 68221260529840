import {StatusBar} from "expo-status-bar";
import {Platform, PlatformColor, useColorScheme} from "react-native";
import {SafeAreaProvider} from "react-native-safe-area-context";
import {configureFonts, MD3LightTheme as PaperDefaultTheme, Provider as PaperProvider,} from "react-native-paper";
import {DefaultTheme, NavigationContainer,} from "@react-navigation/native";
import merge from "deepmerge";
import MainRoute from "./navigation/MainRoute";
import {Provider as ReduxProvider} from "react-redux";
import {store, StoreActions} from "./redux/store";
import {registerRootComponent} from "expo";
import React from "react";
import {MD3Type} from "react-native-paper/lib/typescript/types";
import login from "./login";
import demoModeData from "../demo-mode.json";
import swedishLanguageSettings from './translations/swedish.json'

const fontConfig: Record<string,MD3Type> = {
};

var CombinedDefaultTheme = merge(PaperDefaultTheme, DefaultTheme);
CombinedDefaultTheme = {
    ...CombinedDefaultTheme,
    colors: {
        ...CombinedDefaultTheme.colors,
        primary: "#4050B5",
        secondary:"#a3a3e5",
        tertiary: "#4050B5",
    },
    fonts: configureFonts({config: fontConfig,isV3:true}),
};

const App = (): React.ReactElement => {
    const colorScheme = useColorScheme();

    // // Get rid of the bottom overflow in web browsers
    // // Creates issue with the ReceiptScreen being none scrollable.
    // if (Platform.OS === "web") {
    //     document.getElementsByTagName("body")[0].style.overflow = "hidden";
    // }
    store.dispatch({
        type: StoreActions.TranslationConfiguration,
        payload: swedishLanguageSettings
    });


    return login.authenticated || demoModeData.isOn ? (
        <ReduxProvider store={store}>
            <SafeAreaProvider>
                <PaperProvider
                    theme={
                        CombinedDefaultTheme
                    }
                >
                    <NavigationContainer
                        theme={
                            CombinedDefaultTheme
                        }
                    >
                        <StatusBar style="auto"/>
                        <MainRoute/>
                    </NavigationContainer>
                </PaperProvider>
            </SafeAreaProvider>
        </ReduxProvider>
    ) : <p>Problem with the login.</p>;
};
registerRootComponent(App);
