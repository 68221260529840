import { createStackNavigator } from "@react-navigation/stack";
import ReceiptScreen from "../screens/ReceiptScreen";
import SensorScreen from "../screens/SensorScreen";
import { MainRoutes, MainStackParamList } from "./routes";
import React from "react";

const Stack = createStackNavigator<MainStackParamList>();

const MainRoute = (): React.ReactElement => {

  return (
    <Stack.Navigator>
      <Stack.Screen name={MainRoutes.Sensor} component={SensorScreen} options={{ title: "Sensor" }} />
      <Stack.Screen name={MainRoutes.Receipt} component={ReceiptScreen} options={{ title: "Kvittens" }} />
    </Stack.Navigator>
  );
}
export default MainRoute
