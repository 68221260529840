import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import {Headline, RadioButton, Subheading, Text, TextInput, Title, useTheme} from "react-native-paper";
import { prognosisDescriptions } from "../data/constants";
import { store } from "../redux/store";

type SensorStep3Props = {
    onValueChange: (value: Array<{ value: string, text: string }>) => void;
}

interface DICTIONARY_KEY {
    [key: number]: string;
}

const styles = StyleSheet.create({
    outerView:{
        alignItems: "center",
        marginLeft:16,
        marginRight:16
    },
    subheading:{
        textAlign: "center",
        marginBottom:32
    },
    nextWeekView:{
        alignContent: "flex-start",
        width: "100%"
    },
    nextWeekInput:{
        width: '100%',
    },
    incomingWeekContent:{
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    },
    futureView:{
        alignContent: "flex-start",
        width: "100%",
        marginTop: 16
    },
    futureInput:{
        width: '100%',
    },
    futureContent:{
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    },
    radioButtonView:{
        flexDirection: 'row',
        alignContent: 'center'
    },
    radioButtonText:{
        alignSelf: 'center', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
    }
});

const renderValues = () => {
    let items: React.ReactElement[] = [];
    let values = ["0", "1", "2", "3", "4"]
    let dictionary = store.getState().assessments.translationConfiguration.dictionary;

    const dictionaryKeyValuePrognosis = {
        0: dictionary["much_better"],
        1: dictionary["better"],
        2: dictionary["same"],
        3: dictionary["worse"],
        4: dictionary["much_worse"],
    } as DICTIONARY_KEY


    for (let i = 0; i < values.length; i++) {
        items.push(<View key={i} style={styles.radioButtonView}><RadioButton value={values[i]} /><Text style={styles.radioButtonText}>{dictionaryKeyValuePrognosis[i]}</Text></View>)
    }

    return items;
}

const SensorStep3 = ({ onValueChange }: SensorStep3Props): React.ReactElement => {
    let _nextWeekValue = store.getState().assessments.currentAssessment.prognosisShortterm || 2;
    const [nextWeekValue, setNextWeekValue] = useState<string>(_nextWeekValue + "");
    let _nextWeekText = store.getState().assessments.currentAssessment.prognosisShorttermComment || "";
    const [nextWeekText, setNextWeekText] = useState<string>(_nextWeekText);
    let _futureValue = store.getState().assessments.currentAssessment.prognosisLongterm || 2;
    const [futureValue, setFutureValue] = useState<string>(_futureValue + "");
    let _futureText = store.getState().assessments.currentAssessment.prognosisLongtermComment || "";
    const [futureText, setFutureText] = useState<string>(_futureText);
    const {colors,fonts} = useTheme();

    let dictionary = store.getState().assessments.translationConfiguration.dictionary;

    useEffect(() => {
        onValueChange([{ value: nextWeekValue, text: nextWeekText }, { value: futureValue, text: futureText }])
    }, [nextWeekValue, futureValue, nextWeekText, futureText])

    return (
        <View style={styles.outerView}>
            <Headline>{dictionary["prognosis_title"]}</Headline>
            <Subheading style={styles.subheading}>{dictionary["prognosis_header"]}</Subheading>

            <View style={styles.nextWeekView}>
                <Title>{dictionary["short_term_prognosis_title"]}</Title>
                <RadioButton.Group value={nextWeekValue} onValueChange={(value) => setNextWeekValue(value)}>
                    {renderValues()}
                </RadioButton.Group>
                <TextInput value={nextWeekText} label={dictionary["short_term_prognosis_comment"]} multiline mode="outlined" contentStyle={styles.incomingWeekContent} style={styles.nextWeekInput} onChangeText={(t) => setNextWeekText(t)} />
            </View>

            <View style={styles.futureView}>
                <Title>{dictionary["long_term_prognosis_title"]}</Title>
                <RadioButton.Group value={futureValue} onValueChange={(value) => setFutureValue(value)}>
                    {renderValues()}
                </RadioButton.Group>
                <TextInput value={futureText} label={dictionary["long_term_prognosis_comment"]} multiline mode="outlined" contentStyle={styles.futureContent} style={styles.futureInput} onChangeText={(t) => setFutureText(t)} />
            </View>
        </View>
    );
}
export default SensorStep3;