import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { StyleProp, useColorScheme, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";

export const LinearGradientBar = (): React.ReactElement => {

    const colorScheme = useColorScheme();

    // Colors calculated with https://material.io/design/color/the-color-system.html#tools-for-picking-colors based on the primary color for dark and light theme
    const gradientColors: [string, string, string] = colorScheme === "dark" ? ["#fc86c7", "#fcbb86", "#c7fc86"] : ["#ee008b", "#ee6300", "#8bee00"];

    return (
        <LinearGradient
            start={[0, 0]}
            end={[1, 0]}
            colors={gradientColors}
            style={{ position: "absolute", height: 12, width: "100%", borderRadius: 4 }}
        />
    );
}

export const trackStyle: StyleProp<ViewStyle> = { opacity: 0, height: 24 };

export const thumbStyle = (): StyleProp<ViewStyle> => {
    const { colors } = useTheme();

    // Add transparency to the border color if we can
    const borderColor = colors.background.length === 7 ? colors.background + "40" : colors.background

    return { backgroundColor: colors.tertiary, borderColor: borderColor, borderWidth: 1, width: 24, height: 24 }
}