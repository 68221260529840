import {
  configureStore,
  createAction,
  createReducer,
  PayloadAction,
} from "@reduxjs/toolkit";
import AssessmentState from "../interfaces/AssessmentState";
import StoreState from "../interfaces/StoreState";
import AssessmentEventConfiguration from "../interfaces/AssessmentEventConfiguration";
import LanguageModel from "../interfaces/LanguageModel";

/* Actions */
export enum StoreActions {
  AssessmentAdd = "assessment/add",
  AssessmentCurrent = "assessment/current",
  AssessmentReset = "assessment/reset",
  AssessmentEventConfiguration = "assessment/assessmentEventConfiguration",
  TranslationConfiguration = "translation/current"
}

const addAssessment = createAction<AssessmentState>(StoreActions.AssessmentAdd);
const currentAssessment = createAction<AssessmentState>(StoreActions.AssessmentCurrent,);
const resetAssessment = createAction(StoreActions.AssessmentReset);
const setAssessmentEventConfiguration = createAction<AssessmentEventConfiguration>(StoreActions.AssessmentEventConfiguration,);
const translationConfiguration = createAction<LanguageModel>(StoreActions.TranslationConfiguration);

/* Reducers */
const assessmentReducerInitialState = {
  assessments: [] as AssessmentState[],
  currentAssessment: {} as AssessmentState,
  currentAssessmentEventConfiguration: {} as AssessmentEventConfiguration,
  translationConfiguration: {} as LanguageModel
} as StoreState;

const assessmentReducer = createReducer(
  assessmentReducerInitialState,
  (builder) => {
    builder
      .addCase(
        addAssessment,
        (state, action: PayloadAction<AssessmentState>) => {
          state.assessments.push(action.payload);
        },
      )
      .addCase(
        currentAssessment,
        (state, action: PayloadAction<AssessmentState>) => {
          state.currentAssessment = {
            ...state.currentAssessment,
            ...action.payload,
          };
        },
      )
      .addCase(resetAssessment, (state) => {
        state.currentAssessment = {} as AssessmentState;
      })
      .addCase(
        setAssessmentEventConfiguration,
        (state, action: PayloadAction<AssessmentEventConfiguration>) => {
          state.currentAssessmentEventConfiguration = action.payload;
        },
      ).addCase(
          translationConfiguration,
        (state,action: PayloadAction<LanguageModel>) => {
            state.translationConfiguration = action.payload
        }
    );
  },
);

/* Store */
export const store = configureStore({
  reducer: {
    assessments: assessmentReducer,
  },
});