import React, { useEffect, useState } from "react";
import {Platform, View, StyleSheet} from "react-native";
import { Headline, Subheading, TextInput } from "react-native-paper";
import { store } from "../redux/store";
import Svg from "react-native-svg";

type SensorStep5Props = {
    onValueChange: (value: [string, string]) => void;
}

const SensorStep5 = ({ onValueChange }: SensorStep5Props) => {
    let _shortSpanText = store.getState().assessments.currentAssessment.actionsShortterm || "";
    const [shortSpanText, setShortSpanText] = useState<string>(_shortSpanText);
    let _longSpanText = store.getState().assessments.currentAssessment.actionsLongterm || "";
    const [longSpanText, setLongSpanText] = useState<string>(_longSpanText);

    let dictionary = store.getState().assessments.translationConfiguration.dictionary;
    //let textActionHeader = "Vad har du som chef/ledare gjort för att hantera de påverkansfaktorer du identifierat. Svara kortfattat i fritextfältet.";
    useEffect(() => {
        onValueChange([shortSpanText, longSpanText]);
    }, [shortSpanText, longSpanText])

    const styles = StyleSheet.create({
        outerView: {
            alignItems: "center",
            marginLeft: 16,
            marginRight: 16
        },
        subheading:{
            textAlign: "center",
            marginBottom: 32
        },
        textInputShortSpan:{
            width: "100%",
            marginBottom: 16
        },
        textInputLongSpan:{
            width: "100%"
        }
    });

    return (
        <View style={styles.outerView}>
            <Headline>{dictionary["actions_title"]}</Headline>
            <Subheading style={styles.subheading}>{dictionary["actions_header"]}</Subheading>

            <TextInput value={shortSpanText} label={dictionary["action_short_term_comment"]} multiline mode="outlined" style={styles.textInputShortSpan} onChangeText={(t) => setShortSpanText(t)} />

            <TextInput value={longSpanText} label={dictionary["action_long_term_comment"]} multiline mode="outlined" style={styles.textInputLongSpan} onChangeText={(t) => setLongSpanText(t)} />
        </View>
    );
}
export default SensorStep5;