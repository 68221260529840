import React, {useContext, useEffect, useState} from "react";
import {Text, View, StyleSheet} from "react-native";
import {useTheme} from "react-native-paper";
import {ReactReduxContext} from "react-redux";
import {Endpoint, makeApiRequest, RequestMethod} from "../api/api";
import ProgressStep from "../components/ProgressSteps-custom/ProgressStep";
import ProgressSteps from "../components/ProgressSteps-custom/ProgressSteps";
import {MainNavigationProp} from "../navigation/props";
import {MainRoutes} from "../navigation/routes";
import {store, StoreActions} from "../redux/store";
import SensorStep1 from "../sensor-steps/SensorStep1";
import SensorStep2 from "../sensor-steps/SensorStep2";
import SensorStep3 from "../sensor-steps/SensorStep3";
import SensorStep4 from "../sensor-steps/SensorStep4";
import SensorStep5 from "../sensor-steps/SensorStep5";
import assessmentJson from "../../demo-data.json";
import demoModeData from "../../demo-mode.json";
import login from "../login";

type SensorScreenProps = {
    navigation: MainNavigationProp<MainRoutes.Sensor>;
};

const styles = StyleSheet.create({
    outerView:{
        flex: 1,
    },
    buttonText: {
        fontWeight:"bold"
    },
});

const keycloakProperies = {
    token: login.token || ""
}

const nextButtonText = "Nästa";
const previousButtonText = "Backa";
const submitButtonText = "Skicka";


const SensorScreen = ({
                          navigation,
                      }: SensorScreenProps): React.ReactElement => {
    const {store} = useContext(ReactReduxContext);
    const [assessmentAvailable, setAssessmentAvailable] = useState(false);
    const [assessmentEventType, setAssesmentEventType] = useState(0);
    const {colors,fonts} = useTheme();

    let dictionary = store.getState().assessments.translationConfiguration.dictionary;

    // ----------------------------------------------------------------
    // GETS config from server
    // ----------------------------------------------------------------

    useEffect(() => {
        console.log(keycloakProperies.token)
        if(!demoModeData.isOn){
            makeApiRequest(Endpoint.GetUnitAssessmentEventAvailable, RequestMethod.GET, keycloakProperies.token )
                .then((response) => {
                    // In my mock server I send status 204 if unavailable
                    if (response.status === 200) {
                        setAssessmentAvailable(true);
                        console.log(response.data)
                        store.dispatch({
                            type: StoreActions.AssessmentEventConfiguration,
                            payload: response.data,
                        });
                        store.dispatch({ type: StoreActions.AssessmentCurrent, payload: {type: response.data.configuration.type}});
                        setAssesmentEventType(response.data.configuration.type);
                    }
                })
                .catch((reason) => {
                    // TODO Handle errors
                    console.log("[API ERROR]:\n" + JSON.stringify(reason, null, 2));
                });
        } else {
            setAssessmentAvailable(true);
            store.dispatch({
                type: StoreActions.AssessmentEventConfiguration,
                payload: assessmentJson.currentAssessmentEventConfiguration
            });
            store.dispatch({
                type: StoreActions.AssessmentCurrent,
                payload: assessmentJson.currentAssessment
            });
        }
    }, []);

    // ----------------------------------------------------------------
    // POST information to server
    // ----------------------------------------------------------------

    const submit = () => {
        if(!demoModeData.isOn) {
            makeApiRequest(
                Endpoint.CreateUnitAssessment,
                RequestMethod.POST,
                keycloakProperies.token,
                store.getState().assessments.currentAssessment,
                store.getState().assessments.currentAssessmentEventConfiguration
                    .assessmentEventId,
            )
                .then(() => {
                    store.dispatch({
                        type: StoreActions.AssessmentAdd,
                        payload: store.getState().assessments.currentAssessment,
                    });
                    console.log(assessmentAvailable);
                    console.log(store.getState().assessments.currentAssessment);
                    console.log(store.getState().currentAssessmentEventConfiguration)
                    navigation.replace(MainRoutes.Receipt);
                })
                .catch((reason) => {
                    // TODO Handle errors
                    console.log(store.getState().assessments.currentAssessment);
                    console.log(store.getState().currentAssessmentEventConfiguration)
                    console.warn("[API ERROR]: \n" + JSON.stringify(reason, null, 2));
                });
        } else {
            navigation.replace(MainRoutes.Receipt);
        }
    };

    // ----------------------------------------------------------------
    // STEPS of Type 0 version
    // ----------------------------------------------------------------

    const TypeZeroSteps = () => {
        return (

            <ProgressSteps
                progressBarColor={colors.tertiary}
                disabledStepIconColor={colors.tertiary}
                activeStepIconBorderColor={colors.secondary}
                activeStepIconColor={colors.primary}
                completedProgressBarColor={colors.primary}
                completedStepIconColor={colors.primary}
                stepIconSize={60}
                topOffset={16}
                bottomOffset={0}
            >
                <ProgressStep
                    nextBtnText={dictionary["next_button"]}
                    previousBtnText={dictionary["previous_button"]}
                    finishBtnText={dictionary["send_button"]}
                    nextBtnDisabled={!assessmentAvailable}
                    nextBtnTextStyle={styles.buttonText}
                    previousBtnTextStyle={styles.buttonText}

                >
                    <SensorStep1
                        onValueChange={(data) => {
                            store.dispatch({
                                type: StoreActions.AssessmentCurrent,
                                payload: {assessmentMain: data},
                            })
                        }}
                    />
                </ProgressStep>
                <ProgressStep
                    nextBtnText={dictionary["next_button"]}
                    previousBtnText={dictionary["previous_button"]}
                    finishBtnText={dictionary["send_button"]}
                    nextBtnTextStyle={styles.buttonText}
                    previousBtnTextStyle={styles.buttonText}

                >
                    <SensorStep2
                        onValueChange={(data) =>
                            store.dispatch({
                                type: StoreActions.AssessmentCurrent,
                                payload: {assessmentMin: data[0], assessmentMax: data[1]},
                            })
                        }
                    />
                </ProgressStep>
                <ProgressStep
                    nextBtnText={dictionary["next_button"]}
                    previousBtnText={dictionary["previous_button"]}
                    finishBtnText={dictionary["send_button"]}
                    scrollable={true}
                    nextBtnTextStyle={styles.buttonText}
                    previousBtnTextStyle={styles.buttonText}
                >
                    <SensorStep3
                        onValueChange={(data) =>
                            store.dispatch({
                                type: StoreActions.AssessmentCurrent,
                                payload: {
                                    prognosisShortterm: +data[0].value,
                                    prognosisShorttermComment: data[0].text,
                                    prognosisLongterm: +data[1].value,
                                    prognosisLongtermComment: data[1].text,
                                },
                            })
                        }
                    />
                </ProgressStep>
                <ProgressStep
                    nextBtnText={dictionary["next_button"]}
                    previousBtnText={dictionary["previous_button"]}
                    finishBtnText={dictionary["send_button"]}
                    nextBtnTextStyle={styles.buttonText}
                    previousBtnTextStyle={styles.buttonText}
                >
                    <SensorStep4
                        onValueChange={(data) =>
                            store.dispatch({
                                type: StoreActions.AssessmentCurrent,
                                payload: {impactfactors: data},
                            })
                        }
                    />
                </ProgressStep>
                <ProgressStep
                    nextBtnText={dictionary["next_button"]}
                    previousBtnText={dictionary["previous_button"]}
                    finishBtnText={dictionary["send_button"]}
                    onSubmit={submit}
                    nextBtnTextStyle={styles.buttonText}
                    previousBtnTextStyle={styles.buttonText}
                >
                    <SensorStep5
                        onValueChange={(data) =>
                            store.dispatch({
                                type: StoreActions.AssessmentCurrent,
                                payload: {
                                    actionsShortterm: data[0],
                                    actionsLongterm: data[1],
                                },
                            })
                        }
                    />
                </ProgressStep>
            </ProgressSteps>
        );
    };

    // ----------------------------------------------------------------
    // STEPS of Type 1 version
    // ----------------------------------------------------------------

    const TypeOneSteps = () => {
        return (
            <ProgressSteps
                progressBarColor={colors.tertiary}
                disabledStepIconColor={colors.tertiary}
                activeStepIconBorderColor={colors.secondary}
                activeStepIconColor={colors.primary}
                completedProgressBarColor={colors.primary}
                completedStepIconColor={colors.primary}
                stepIconSize={60}
                topOffset={16}
                bottomOffset={0}
            >
                <ProgressStep
                    nextBtnText={dictionary["next_button"]}
                    previousBtnText={dictionary["previous_button"]}
                    finishBtnText={dictionary["send_button"]}
                    nextBtnDisabled={!assessmentAvailable}
                    nextBtnTextStyle={styles.buttonText}
                    previousBtnTextStyle={styles.buttonText}

                >
                    <SensorStep1
                        onValueChange={(data) =>
                            store.dispatch({
                                type: StoreActions.AssessmentCurrent,
                                payload: {assessmentMain: data},
                            })
                        }
                    />
                </ProgressStep>
                <ProgressStep
                    nextBtnText={dictionary["next_button"]}
                    previousBtnText={dictionary["previous_button"]}
                    finishBtnText={dictionary["send_button"]}
                    scrollable={true}
                    nextBtnTextStyle={styles.buttonText}
                    previousBtnTextStyle={styles.buttonText}
                >
                    <SensorStep3
                        onValueChange={(data) =>
                            store.dispatch({
                                type: StoreActions.AssessmentCurrent,
                                payload: {
                                    prognosisShortterm: +data[0].value,
                                    prognosisShorttermComment: data[0].text,
                                    prognosisLongterm: +data[1].value,
                                    prognosisLongtermComment: data[1].text,
                                },
                            })
                        }
                    />
                </ProgressStep>
                <ProgressStep
                    nextBtnText={dictionary["next_button"]}
                    previousBtnText={dictionary["previous_button"]}
                    finishBtnText={dictionary["send_button"]}
                    onSubmit={submit}
                    nextBtnTextStyle={styles.buttonText}
                    previousBtnTextStyle={styles.buttonText}
                >
                    <SensorStep4
                        onValueChange={(data) =>
                            store.dispatch({
                                type: StoreActions.AssessmentCurrent,
                                payload: {impactfactors: data},
                            })
                        }
                    />
                </ProgressStep>
            </ProgressSteps>
        );
    };

    // ----------------------------------------------------------------
    // RENDERS the page
    // ----------------------------------------------------------------

    return (
        <View style={styles.outerView}>
            {assessmentEventType == 0 ? (
                <TypeZeroSteps/>
            ) : assessmentEventType == 1 ? (
                <TypeOneSteps/>
            ) : null}
        </View>
    );
};

export default SensorScreen;
