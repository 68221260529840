import React, { useContext, useState } from "react";
import { Platform, ScrollView, StyleSheet, View, NativeModules,Text, Button } from "react-native";
import {
  Card,
  Headline,
  Paragraph,
  Subheading,
  useTheme,
  Divider,
} from "react-native-paper";
import { ReactReduxContext } from "react-redux";
import {
  assessmentDescriptions,
  impactFactorDescriptions,
  prognosisDescriptions,
} from "../data/constants";
import KeyValuePair from "../interfaces/KeyValuePair";
import { MainNavigationProp } from "../navigation/props";
import { MainRoutes } from "../navigation/routes";
import {store} from "../redux/store";

type ReceiptScreenProps = {
  navigation: MainNavigationProp<MainRoutes.Receipt>;
};

const ReceiptScreen = ({
                         navigation,
                       }: ReceiptScreenProps): React.ReactElement => {
  const { store } = useContext(ReactReduxContext);

  const currentAssessment = store.getState().assessments.currentAssessment;
  const assessmentEventType =
    store.getState().assessments.currentAssessmentEventConfiguration
      .configuration.type;
  const { colors ,fonts} = useTheme();
  let dictionary = store.getState().assessments.translationConfiguration.dictionary;

  const styles = StyleSheet.create({
    cards: {
      marginVertical: 8,
      minHeight: 0,
    },
    values: {
      color: colors.primary,
    },
    valuesText: {
      color: colors.primary,
      fontStyle: "italic",
    },
    divider: {
      marginLeft: 12,
      marginRight:12,
      marginBottom: 10,
    },
    subHeading:{
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    },
    title: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontWeight: '500',

    },
    scrollView:{
      padding: 16
    },
    headline:{
      margin: 0,
      marginBottom: 4,
      fontWeight:"500"
    },
    bottomCardStyle:{
      marginTop: 0,
      marginBottom: 40,
      padding: 0

    },
    buttonText:{
      color: "#FFFFFF",
      alignContent:"center"
    },
  });

  const TypeOneReceipt = () => {
    return (
      <ScrollView style={styles.scrollView}>
        <Headline style={styles.headline}>
          Din bedömning
        </Headline>
        <Card style={styles.cards}>
          <Card.Title title="Veckoskattning" titleStyle={styles.title} />
          <Divider style={styles.divider}/>
          <Card.Content>
            <Paragraph style={styles.values}>
              {currentAssessment.assessmentMain}/10,{" "}
              {assessmentDescriptions[
                currentAssessment.assessmentMain
              ].toLowerCase()}
            </Paragraph>
          </Card.Content>
        </Card>
        <Card style={styles.cards}>
          <Card.Title title="Prognos" titleStyle={styles.title} />
          <Divider style={styles.divider}/>
          <Card.Content>
            <Subheading style={styles.subHeading}>Kommande vecka</Subheading>
            <Paragraph style={styles.values}>
              {prognosisDescriptions[currentAssessment.prognosisShortterm]}
            </Paragraph>
            <Paragraph style={styles.values}>
              {currentAssessment.prognosisShorttermComment}
            </Paragraph>
            <Subheading style={styles.subHeading}>Längre tid</Subheading>
            <Paragraph style={styles.values}>
              {prognosisDescriptions[currentAssessment.prognosisLongterm]}
            </Paragraph>
            <Paragraph style={styles.values}>
              {currentAssessment.prognosisLongtermComment}
            </Paragraph>
          </Card.Content>
        </Card>
        <Card style={styles.cards}>
          <Card.Title title="Påverkansfaktorer" titleStyle={styles.title} />
          <Divider style={styles.divider}/>
          <Card.Content>
            {currentAssessment.impactfactors.map(
              (impactFactor: KeyValuePair, index: number) => {
                return (
                  <View
                    key={impactFactor.key}
                    style={{
                      marginBottom:
                        index < currentAssessment.impactfactors.length - 1
                          ? 8
                          : 0,
                    }}
                  >
                    <Subheading style={styles.subHeading}>{impactFactor.displayName}</Subheading>
                    <Paragraph style={styles.values}>
                      {impactFactorDescriptions[impactFactor.value]}
                    </Paragraph>
                    {!impactFactor.commentVisible ? null : (
                      <Paragraph style={styles.values}>
                        {impactFactor.comment}
                      </Paragraph>
                    )}
                  </View>
                );
              },
            )}
          </Card.Content>
        </Card>
      </ScrollView>
    );
  };



  return assessmentEventType == 0 ? (
    <ScrollView style={styles.scrollView}>
      <Headline style={styles.headline}>{dictionary["your_assessment"]}</Headline>

      <Card style={styles.cards}>
        <Card.Title title={dictionary["main_title"]} titleStyle={styles.title}/>
        <Divider style={styles.divider}/>
        <Card.Content>
          <Paragraph style={styles.values}>
            {currentAssessment.assessmentMain}/10,{" "}
            {dictionary[assessmentDescriptions[currentAssessment.assessmentMain].toLowerCase()]}
          </Paragraph>
        </Card.Content>
      </Card>

      <Card style={styles.cards}>
        <Card.Title title={dictionary["min_max_title"]} titleStyle={styles.title} />
        <Divider style={styles.divider}/>
        <Card.Content>
          <Subheading style={styles.subHeading}>{dictionary["min"]}</Subheading>
          <Paragraph style={styles.values}>
            {currentAssessment.assessmentMin}/10,{" "}
            {dictionary[assessmentDescriptions[currentAssessment.assessmentMin].toLowerCase()]}
          </Paragraph>
          <Subheading style={styles.subHeading}>{dictionary["max"]}</Subheading>
          <Paragraph style={styles.values}>
            {currentAssessment.assessmentMax}/10,{" "}
            {dictionary[assessmentDescriptions[currentAssessment.assessmentMax].toLowerCase()]}
          </Paragraph>
        </Card.Content>
      </Card>

      <Card style={styles.cards}>
        <Card.Title title={dictionary["prognosis_title"]} titleStyle={styles.title} />
        <Divider style={styles.divider}/>
        <Card.Content>
          <Subheading>{dictionary["short_term_prognosis_title"]}</Subheading>
          <Paragraph style={styles.values}>
            {dictionary[prognosisDescriptions[currentAssessment.prognosisShortterm]]}
          </Paragraph>
          <Paragraph style={styles.valuesText}>
            {dictionary[currentAssessment.prognosisShorttermComment]}
          </Paragraph>
          <Subheading>{dictionary["long_term_prognosis_title"]}</Subheading>
          <Paragraph style={styles.values}>
            {dictionary[prognosisDescriptions[currentAssessment.prognosisLongterm]]}
          </Paragraph>
          <Paragraph style={styles.valuesText}>
            {dictionary[currentAssessment.prognosisLongtermComment]}
          </Paragraph>
        </Card.Content>
      </Card>

      <Card style={styles.cards}>
        <Card.Title title={dictionary["impact_factor_title"]} titleStyle={styles.title} />
        <Divider style={styles.divider}/>
        <Card.Content>
          {currentAssessment.impactfactors.map(
            (impactFactor: KeyValuePair, index: number) => {
              return (
                <View
                  key={impactFactor.key}
                  style={{
                    marginBottom:
                      index < currentAssessment.impactfactors.length - 1
                        ? 8
                        : 0,
                  }}
                >
                  <Subheading>{impactFactor.displayName}</Subheading>
                  <Paragraph style={styles.values}>
                    {dictionary[impactFactorDescriptions[impactFactor.value]]}
                  </Paragraph>
                  {!impactFactor.commentVisible ? null : (
                    <Paragraph style={styles.valuesText}>
                      {impactFactor.comment}
                    </Paragraph>
                  )}
                </View>
              );
            },
          )}
        </Card.Content>
      </Card>

      <Card style={styles.cards}>
        <Card.Title title={dictionary["actions_title"]} titleStyle={styles.title} />
        <Divider style={styles.divider}/>
        <Card.Content>
          <Subheading>{dictionary["action_short_term_comment"]}</Subheading>
          <Paragraph style={styles.valuesText}>
            {currentAssessment.actionsShortterm}
          </Paragraph>
          <Subheading>{dictionary["action_long_term_comment"]}</Subheading>
          <Paragraph style={styles.valuesText}>
            {currentAssessment.actionsLongterm}
          </Paragraph>
        </Card.Content>
      </Card>

      <Card style={styles.bottomCardStyle}>
        <Button title={dictionary["new_assessment_button"]} color="#4050B5" onPress={() => navigation.replace(MainRoutes.Sensor)}/>
      </Card>

    </ScrollView>

  ) : assessmentEventType == 1 ? (
    <TypeOneReceipt />
  ) : (
    (null as unknown as React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    >)
  );
};
export default ReceiptScreen;
