import { RangeSlider } from "@sharcoux/slider";
import React, { useState } from "react"
import { View } from "react-native"
import { LinearGradientBar, thumbStyle, trackStyle } from "./Theming";

type ThemedRangeSliderProps = {
    value?: [number, number];
    step?: number;
    minimumValue?: number;
    maximumValue?: number;

    onValueChange?: (value: [number, number]) => void;
}

const ThemedRangeSlider = ({ value, step = 1, minimumValue = 1, maximumValue = 10, onValueChange }: ThemedRangeSliderProps): React.ReactElement => {
    value = value && value[0] && value[1] ? value : [3, 8];
    const [rangeValue, setRangeValue] = useState<[number, number]>(value);

    return (
        <View style={{ display: "flex", borderColor: "transparent", justifyContent: "center", marginLeft: 32, marginRight: 32, marginBottom: 16 }}>
            <LinearGradientBar />
            <RangeSlider
                step={step}
                range={rangeValue}
                minimumValue={minimumValue}
                maximumValue={maximumValue}
                onValueChange={(range) => { setRangeValue(range); if (onValueChange) onValueChange(range) }}

                trackStyle={trackStyle}
                thumbStyle={thumbStyle()}
            />
        </View>
    );
}
export default ThemedRangeSlider;