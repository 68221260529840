import React, {Component} from 'react';
import {Platform, ScrollView, View,Text} from 'react-native';
import PropTypes from 'prop-types';

import ProgressButtons from './ProgressButtons';
import {Button} from 'react-native-paper';

class ProgressStep extends Component {
    onNextStep = async () => {
        this.props.onNext && (await this.props.onNext());

        // Return out of method before moving to next step if errors exist.
        if (this.props.errors) {
            return;
        }

        this.props.setActiveStep(this.props.activeStep + 1);
    };

    onPreviousStep = () => {
        // Changes active index and calls previous function passed by parent
        this.props.onPrevious && this.props.onPrevious();
        this.props.setActiveStep(this.props.activeStep - 1);
    };

    onSubmit = () => {
        this.props.onSubmit && this.props.onSubmit();
    };

    renderNextButton = () => {

        return (
            <Button mode="contained"
                    onPress={this.props.activeStep === this.props.stepCount - 1 ? this.onSubmit : this.onNextStep}
                    disabled={this.props.nextBtnDisabled || this.props.errors}
                    style={this.props.nextBtnStyle}
            >
                <Text style={this.props.nextBtnTextStyle}>
                {this.props.activeStep === this.props.stepCount - 1 ? this.props.finishBtnText : this.props.nextBtnText}
                    </Text>
            </Button>
        );
    };

    renderPreviousButton = () => {

        return (
            <Button
                onPress={this.onPreviousStep}
                disabled={this.props.previousBtnDisabled}

            >
                <Text style={this.props.nextBtnTextStyle}>
                {this.props.activeStep === 0 ? '' : this.props.previousBtnText}
                </Text>
            </Button>
        );
    };

    render() {
        const scrollViewProps = this.props.scrollViewProps || {};
        const viewProps = this.props.viewProps || {};
        const isScrollable = this.props.scrollable;
        const buttonRow = this.props.removeBtnRow ? null : (
            <ProgressButtons
                renderNextButton={this.renderNextButton}
                renderPreviousButton={this.renderPreviousButton}
            />
        );

        // Web scrolling fix
        let webStyle = Platform.OS === "web" ? {flex: "auto", height: 1} : {};

        return (
            <View style={{flex: 1}}>
                {isScrollable
                    ? <ScrollView {...scrollViewProps} style={{...webStyle}}>{this.props.children}</ScrollView>
                    : <View {...viewProps}>{this.props.children}</View>}

                {buttonRow}
            </View>
        );
    }
}

ProgressStep.propTypes = {
    label: PropTypes.string,
    onNext: PropTypes.func,
    onPrevious: PropTypes.func,
    onSubmit: PropTypes.func,
    setActiveStep: PropTypes.func,
    nextBtnText: PropTypes.string,
    previousBtnText: PropTypes.string,
    finishBtnText: PropTypes.string,
    stepCount: PropTypes.number,
    nextBtnStyle: PropTypes.object,
    nextBtnTextStyle: PropTypes.object,
    nextBtnDisabled: PropTypes.bool,
    previousBtnStyle: PropTypes.object,
    previousBtnTextStyle: PropTypes.object,
    previousBtnDisabled: PropTypes.bool,
    scrollViewProps: PropTypes.object,
    viewProps: PropTypes.object,
    errors: PropTypes.bool,
    removeBtnRow: PropTypes.bool,
    scrollable: PropTypes.bool
};

ProgressStep.defaultProps = {
    nextBtnText: 'Next',
    previousBtnText: 'Previous',
    finishBtnText: 'Submit',
    nextBtnDisabled: false,
    previousBtnDisabled: false,
    errors: false,
    removeBtnRow: false,
    scrollable: true,

};

export default ProgressStep;
