import Slider from "@sharcoux/slider";
import React, { useState } from "react";
import { View } from "react-native";
import { useTheme } from "react-native-paper";
import { LinearGradientBar } from "./Theming";

type ThemedSliderProps = {
    value?: number;
    step?: number;
    minimumValue?: number;
    maximumValue?: number;

    onValueChange?: (value: number) => void;
}

const ThemedSlider = ({ value, step = 1, minimumValue = 1, maximumValue = 10, onValueChange }: ThemedSliderProps): React.ReactElement => {
    value = value ? value : 6;
    const [sliderValue, setSliderValue] = useState<number>(value);
    const { colors } = useTheme();

    return (
        <View style={{ justifyContent: "center", marginLeft: 32, marginRight: 32, marginBottom: 8 }}>
            <LinearGradientBar />
            <Slider
                step={step}
                value={sliderValue}
                minimumValue={minimumValue}
                maximumValue={maximumValue}
                onValueChange={(value) => { setSliderValue(value); if (onValueChange) onValueChange(value) }}

                trackStyle={{ opacity: 0, height: 24 }}
                thumbStyle={{ backgroundColor: colors.tertiary, borderColor: "#10101040", borderWidth: 1, width: 24, height: 24 }}
            />
        </View>
    );
}
export default ThemedSlider;